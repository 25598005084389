import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import BlocCta2Cols from '../components/BlocCta2Cols'
import BlocImageGaucheTexte from '../components/BlocImageGaucheTexte'
import BlocImageDroiteTexte from '../components/BlocImageDroiteTexte'
import BlocVertTexteCTA from '../components/BlocVertTexteCTA'
import BlocImageFullWidth from '../components/BlocImageFullWidth'
import Header from '../components/Header'
import Footer from '../components/Footer'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import SEO from '../components/SEO'
import Calendly from '../components/Calendly'

function Features(props) {
  const [language, setLanguage] = useState('1')
  const [nodeLocale, setNodeLocale] = useState('')
  const [popupCalendly, setPopupCalendly] = useState(false)
  const [filter, setFilter] = useState('All')

  const getLanguage = (data) => {
    if (data === '0') {
      setLanguage(0)
      setNodeLocale('en-US')
    } else if (data === '1') {
      setLanguage(1)
      setNodeLocale('fr')
    }
  }

  const closePopupCalendly = () => {
    setPopupCalendly(false)
  }

  const handleChange = () => {}

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      if (localStorage.getItem('language') === '0') {
        setLanguage(0)
        setNodeLocale('en-US')
      } else if ((localStorage.getItem('language')) === '1') {
        setLanguage(1)
        setNodeLocale('fr')
      }
    }
  }, [nodeLocale])

  const data = props.data
  const {
    headline,
    excerpt,
    blocCtaBtns,
    heroImage,
    modularArea
  } = props.data.allContentfulFeatures.nodes[`${language}`]
  const header = data.allContentfulSousMenu.nodes
  const postNode = data.allContentfulFeatures.nodes[`${language}`]
  const partnersCategories = data.allContentfulCategoriesFeatures.edges.sort((a, b) => {
    const titreA = a.node.titre.toLowerCase()
    const titreB = b.node.titre.toLowerCase()
    if (titreA < titreB) return -1
    if (titreA > titreB) return 1
    return 0
  })

  return (
    <div>
      <Header headerContent={header} infoPage={props} language={language} getLanguage={getLanguage}/>
      <Layout data={data}>
        <SEO pagePath="/features" postNode={postNode} pageSEO />

        <div className="wrapper" id="wrapper-features">
          <main className="features">
            <section className="section-1">
              <div className="container">
                <div className="row">
                  <div className="columns col-lg-6">
                    <div className="box-item">
                      <h1 className="headline">{headline}</h1>
                      <h2 className="description">{excerpt}</h2>
                      <div className="box-btns">
                        <button className="btn btn-primary" onClick={(e) => setPopupCalendly(true)}><span>{blocCtaBtns.ctaLienGauche}</span></button>
                        <Link to={blocCtaBtns.ctaUrlLienDroite} title=""><button className="btn btn-primary btn-blanc"><span>{blocCtaBtns.ctaLienDroite}</span></button></Link>
                      </div>
                    </div>
                  </div>
                  <div className="columns col-lg-6">
                    <div className="box-bitmap">
                      <img src={heroImage.file.url} alt={heroImage.description}/>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section-2">
              <div className="container">
                <ul className="partners filters">
                  {partnersCategories
                    .filter(({ node }) => node.node_locale === nodeLocale)
                    .map(({ node }) =>
                      <li className={filter === node.filter ? 'checked' : '' } key={node.filter}>
                        <AnchorLink onClick={(e) => setFilter(node.filter)} offset="100" href={`#${node.filter}`} title="">{node.titre}</AnchorLink>
                      </li>
                  )}
                </ul>

                <select className="partners filters" onBlur={(e) => handleChange(e)}>
                  <option className={filter === 'All' ? 'checked' : '' } value="All">{language === '0' ? 'All' : 'Tous'}</option>
                    {partnersCategories
                      .filter(({ node }) => node.node_locale === nodeLocale)
                      .map(({ node }) => <option className={filter === node.filter ? 'checked' : '' } value={node.filter} key={node.filter}>{node.titre}</option>
                    )}
                </select>
              </div>
            </section>

            {modularArea.map((item) =>
              item.typeBlocImage === 'Image à gauche' ? <BlocImageGaucheTexte data={item}/> :
              item.typeBlocImage === 'Image à droite' ? <BlocImageDroiteTexte data={item}/> :
              item.typeBlocImage === '2 Col Headline & Texte' ? <BlocImageFullWidth data={item}/> :
              item.typeBlocImage === 'Fond Vert & CTA' ? <BlocVertTexteCTA data={item}/> :
              item.typeBlocImage === 'ContentfulBlocCta' ? <BlocCta2Cols data={item}/> : null
            )}

            <Calendly data={popupCalendly} closePopup={closePopupCalendly}/>
          </main>
        </div>
      </Layout>
      <Footer getLanguage={getLanguage}/>
    </div>
  )
}

export const query = graphql`
  query FeaturesQuery {
    allContentfulSousMenu {
      nodes {
        contentful_id
        node_locale
        icone {
          file {
            url
          }
        }
        titre
        baselineMenu {
          baselineMenu
        }
      }
    }
    allContentfulCategoriesFeatures {
      edges {
        node {
          id
          titre
          filter
          node_locale
        }
      }
    }
    allContentfulFeatures {
      nodes {
        id
        node_locale
        titre
        metaDescription {
          metaDescription
          internal {
            content
          }
        }
        headline
        excerpt
        blocCtaBtns {
          ctaLienGauche
          ctaLienDroite
          ctaTexteDroite {
            ctaTexteDroite
          }
          ctaTexteGauche {
            ctaTexteGauche
          }
        }
        heroImage {
          description
          file {
            url
          }
        }
        modularArea {
          ... on ContentfulBlocImageTexte {
            headline
            ancre
            typeBlocImage
            lienInterne
            lienTitreCta
            image {
              description
              file {
                url
              }
              contentful_id
            }
            texte {
              texte
            }
            listePucesDeFeatures {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulBlocCta {
            __typename
            ctaUrlLienDroite
            ctaUrlLienGauche
            ctaLienGauche
            ctaLienDroite
            ctaTexteGauche {
              ctaTexteGauche
            }
            ctaTexteDroite {
              ctaTexteDroite
            }
          }
        }
      }
    }
  }
`

export default Features
